<template>
  <div class="sm:px-5 md:px-5 lg:mx-20% py-5">
    <da-datatable :headers="headers" :values="allMonitorings" :elevation="3" :hidesearch="false">
            <template #action="{ data }">
              <da-button-900 @click.native="goTo(data.telephone,data.name,data.quote_date_after)"  :label="'Contactar'" r2xl swpr class="w-full px-2  tracking-wider rounded-md"><v-icon small color="white">mdi mdi-whatsapp</v-icon></da-button-900>
            </template>
    </da-datatable>
  </div>
</template>
 
<script>
import { mapActions, mapGetters } from 'vuex';
import VuexMixin from '../../mixins/VuexMixin';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
export default {
    mixins: [VuexMixin],
    data: function() {
        return {
            headers: [
                { text: 'Nombre',       value:  'name' },
                { text: 'Prox. Cita', value:  'quote_date_after' },
                { text: 'Prox. Tratamiento',  value:  'treatment_after' },
                { text: 'Teléfono',     value:  'telephone' },
                { text: 'Acción',       value:  'action' }
            ],
            listPatient:  []
        }
    },
    created() {
      this.isLoading = true
      this.onSearch()
      this.isLoading = false
    },
    computed: {
     ...mapGetters(moduleTypes.MONITORING, {
          allMonitorings: fnTypes.getAllTracing
       }),
    },
    methods: {
      ...mapActions(moduleTypes.MONITORING, [
          fnTypes.getAllTracing
      ]),

    onSearch: async function() {
        await this.getAllTracing()
        this.listPatient = this.allMonitorings
      },
    goTo(phone,nombre,fecha){
      window.open(
        'https://api.whatsapp.com/send?phone='+phone+'&text=¡Hola!%20' +nombre+ ',%20me%20comunico%20de%20Dental%20Orrico,%20para%20confirmar%20tu%20cita%20el%20día%20'+fecha,
          '_blank'
        );
    },
  }

}
</script>

<style>

</style>